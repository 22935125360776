.confirm-ownership {
  position: relative;
  max-width: 321px;

  &__header {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 24px 0;
  }

  &__order-tracking-number,
  &__proof-of-identity {
    display: flex;
    margin-bottom: 16px;

    i {
      margin-right: 16px;
    }
  }

  &__note {
    display: inline;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }

  &__note-details {
    display: inline;
    font-size: 12px;
    font-weight: 400;
    margin: 0 0 24px;
    line-height: 15px;
  }
}
