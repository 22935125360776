@import '~@ri-digital/web-style-guide/index.scss';

.button-wrapper {
  width: 100%;
  margin: 32px auto 0;
  position: relative;

  @media only screen and (min-width: 601px) {
    max-width: 288px;
  }
}

.checkout-button {
  text-transform: uppercase;
  display: block;
  padding: 20px 0;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 16px;

  &--loading {
    padding: 28px 0;
  }
}

.black {
  .checkout-button {
    background: $black;
    color: $white;
  }
}

.flyout {
  margin: 16px auto 0;
  max-width: 327px;
}

.black-no-margin {
  margin: 0;

  .checkout-button {
    background: $black;
    color: $white;
    padding: 16px 0;
  }
}
