@import '~@ri-digital/web-style-guide/index.scss';

.underlined-cta {
  @include button-tertiary-action;
}

.delivery-address__link {
  position: absolute;
  right: 24px;
  bottom: 24px;
}
