@font-face {
  font-family: union;
  src: url('https://www.riverisland.com/Assets/RiverIsland/Core/fonts/union/union-regular.woff2')
      format('woff2'),
    url('https://www.riverisland.com/Assets/RiverIsland/Core/fonts/union/union-regular.woff')
      format('woff');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: union;
  src: url('https://www.riverisland.com/Assets/RiverIsland/Core/fonts/union/union-bold.woff2')
      format('woff2'),
    url('https://www.riverisland.com/Assets/RiverIsland/Core/fonts/union/union-bold.woff')
      format('woff');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: icons;
  src: url('https://www.riverisland.com/Assets/RiverIsland/Core/css/_responsive/_scss/ui_lib/icons/icons_20200602.woff?20200602')
    format('woff');
}
