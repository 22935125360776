@import '~@ri-digital/web-style-guide/index.scss';

.flash-message {
  padding: 7px 9px 10px 12px;
  border: 1px solid $green-focus;
  margin: 0 auto;

  &__wrapper {
    margin: 10px;
  }

  &__copy {
    color: $green-focus;
    text-align: center;
    margin: 0;
  }
}
