@import '~@ri-digital/web-style-guide/index.scss';

.stepper {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.step {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: $inactivegrey;

  a {
    text-decoration: underline;
  }
}

.step_number {
  margin: 0;
  display: flex;
  align-items: center;
  color: $inactivegrey;

  span {
    width: 18px;
    min-width: 18px;
    height: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 50%;
    border: 1px solid $inactivegrey;
    background-color: $white;
  }
}

.step_label {
  color: $inactivegrey;
  margin: 0;
  padding-top: 12px;
  text-align: center;
  margin-right: 8px;
}

html[lang='de'] .step_label {
  font-size: 12px;
  line-height: 14px;

  @include breakpoint(large) {
    font-size: 16px;
    line-height: 20px;
  }
}

.step.active {
  .step_number {
    color: $white;

    span {
      border: 1px solid $black;
      background-color: $black;
    }
  }

  .step_label {
    color: $black;
  }
}

.step.completed {
  .step_number {
    span {
      color: $black;
      border: 2px solid $black;
    }
  }

  .step_label {
    color: $black;
  }
}

.step:not(:first-child) .step_number::before,
.step:not(:last-child) .step_number::after {
  content: '';
  width: 50%;
  height: 1px;
  display: flex;
  background-color: $inactivegrey;
}

.step:first-child .step_number {
  justify-content: flex-end;
}

.step.completed .step_number::before,
.step.completed .step_number::after {
  background-color: $black;
}

.step.active .step_number::before {
  background-color: $black;
}
