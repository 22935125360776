@import '~@ri-digital/web-style-guide/index.scss';
@import './fonts.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: $baseFontFamily;
}

body {
  background-color: $white;

  @include breakpoint(large) {
    background-color: $body-background;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: $baseFontFamily;
}

@font-face {
  font-family: icons;
  src: url('https://www.riverisland.com/Assets/RiverIsland/Core/css/_responsive/_scss/ui_lib/icons/icons_20200602.woff?20200602')
    format('woff2');
}
