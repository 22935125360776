@import '~@ri-digital/web-style-guide/index.scss';

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  inset: 0;
  position: fixed;
  z-index: 998;
  width: 100%;
  height: 100%;
  display: block;

  &__close {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    margin: 13px;
    width: fit-content;
    padding: 0;
  }

  &__content {
    position: relative;
    top: 50%;
    left: 50%;
    width: fit-content;
    z-index: 999;
    transform: translate(-50%, -50%);
  }
}
