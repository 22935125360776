@import '~@ri-digital/web-style-guide/index.scss';

.store-detail {
  &__header {
    display: flex;
    align-items: flex-start;
    margin: 48px 0 24px 0;
  }

  &__heading {
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    margin: 0;

    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    :global(.store-confirmation) & {
      font-weight: 700;
      margin: 0 0 24px 0;
    }
  }

  &__distance {
    font-size: 12px;
    color: $placeholder-grey;
    margin: 8px 0 16px 0;
  }

  &__marker {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    margin: 0 24px 0 0;

    &-label {
      position: absolute;
      font-weight: 700;
      margin: -4px 0 0;
    }

    &-image {
      width: 100%;
    }
  }
}
