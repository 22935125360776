@import '~@ri-digital/web-style-guide/index.scss';

.store-confirmation {
  &__heading {
    margin: 0 0 24px 0;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.02em;
  }

  &__opening {
    margin: 24px 0 0 0;
    font-size: 14px;
  }
}
