@import '~@ri-digital/web-style-guide/index.scss';

.container {
  flex: 1;
  margin-top: 12px;

  @include breakpoint(large) {
    margin-top: 40px;
  }
}

.page {
  margin-top: 32px;

  @include breakpoint(large) {
    display: flex;
    align-items: flex-start;
  }
}

.redirect-banner-wrapper {
  display: none;

  @include breakpoint(large) {
    display: block;
  }
}

.content {
  flex: 2;
  background-color: $white;
  position: relative;
  min-height: 400px;

  @include breakpoint(large) {
    padding: 32px;
    border: 1px solid $border;
  }
}
