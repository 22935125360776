@import '~@ri-digital/web-style-guide/index.scss';

.redirect-banner {
  background-color: $white;
  border: solid 1px $error;
  padding: 16px;
  font-size: 14px;
  display: inline-flex;
  gap: 8px;
  border-radius: 4px;

  p {
    margin: 0;
  }

  @include breakpoint(large) {
    margin-top: 32px;
  }
}

.redirect-banner-link {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  display: inline;
  background: $white;
  padding: 0;
  width: initial;
}
