@import '~@ri-digital/web-style-guide/index.scss';

.vat-message {
  background: $lightShadeGrey;
  padding: 4px 16px;
  width: 375px;
  margin-left: -16px;

  @include breakpoint(large) {
    width: auto;
    margin-left: 0;
  }

  &__text {
    margin: 0;
    color: $black;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
  }
}
