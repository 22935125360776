@import '~@ri-digital/web-style-guide/index.scss';

.mybag__product {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  padding: 0;

  @include breakpoint(large) {
    padding-right: 15px;
  }

  &--image {
    flex-shrink: 0;
    height: 99px !important;
    width: 99px !important;
  }

  &--details {
    flex-grow: 1;
    padding-left: 16px;

    @include breakpoint(large) {
      flex-grow: 2;
    }
  }

  &--name {
    margin: 0 0 12px 0;
    font-size: 14px;
    line-height: 1.4;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    height: 39.2px;
    overflow: hidden;
    position: relative;
    color: $black;
    letter-spacing: 0;

    &::after {
      content: '';
      background: linear-gradient(90deg, rgba(247, 243, 237, 0), $stone 50%);
      position: absolute;
      right: 0;
      bottom: 0;
      width: 20%;
      height: 21px;
      text-align: right;
    }
  }

  &--unlimited-delivery {
    display: block;
    color: $black;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    margin: 0;
  }

  &--price {
    color: $grey;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    letter-spacing: 0.25px;

    &--discounted {
      margin: 0 12px 0 0;
      color: $error;
    }

    &--original {
      color: hsla(0deg, 0%, 45.1%, 0.6);
      text-decoration: line-through;
    }
  }

  &--more {
    display: flex;
    flex-basis: auto;
    flex-wrap: wrap;
    padding-right: 4px;
    margin-top: 13px;
  }

  &--qty,
  &--size {
    color: $black;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    font-weight: 400;
  }

  &--qty {
    min-width: 54px;
    padding-right: 10px;
  }

  &--size {
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--info {
    display: block;
    font-size: 12px;
    line-height: 18px;
    color: $black;
    letter-spacing: 0.25px;
    margin: 3px 0 19px 0;
  }
}

.order-confirm-products {
  &.mybag__product {
    @include breakpoint(large) {
      padding-right: 0;
    }
  }

  .mybag__product--name {
    &::after {
      content: none;
    }
  }

  .mybag__product--price {
    float: right;
  }

  .mybag__product--more {
    flex-direction: column-reverse;
  }
}
