@import '~@ri-digital/web-style-guide/index.scss';

.opening-times {
  width: 100%;
  max-width: 320px;

  table,
  tr {
    width: 100%;
    line-height: 1.5em;
  }

  td {
    width: 50%;
    padding: 0 0 8px 0;

    &:last-child {
      text-align: right;
    }
  }
}
