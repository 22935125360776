@import '~@ri-digital/web-style-guide/index.scss';

.collection-details {
  margin: 24px 0 0 0;
  border-top: 1px solid $light-border;

  div {
    max-width: 340px;
  }

  &__heading {
    text-align: center;
    margin: 16px 0 32px 0;
  }

  &__input-label {
    font-size: 14px;
    margin-bottom: 4px;
  }

  &__input input {
    max-width: 340px;
    height: 48px;
  }

  &__phone-number {
    display: flex;
    position: relative;

    div {
      width: 270px;
    }
  }

  &__phone-number-prepend {
    border: 1px solid rgba(37, 37, 37, 0.4);
    border-right: none;
    margin-bottom: 24px;
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    width: 70px;
    padding: 8px;
  }

  &__phone-number-text {
    margin-bottom: 54px;

    p {
      left: 0;
      position: absolute;
    }
  }

  &__cta {
    @include button-primary-action;

    display: block;
    font-weight: 400;
    width: 100%;
    padding: 20px 0;
    margin: 24px auto;

    @include breakpoint(large) {
      width: 280px;
    }
  }
}
