@import '~@ri-digital/web-style-guide/index.scss';

.address-description-list {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    font-family: $baseFontFamily;
    color: $black;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.3px;
  }
}
