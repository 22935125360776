@import '~@ri-digital/web-style-guide/index.scss';

.us-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 304px;
  height: 387px;
  background-color: #f4f4f4;
  border-radius: 8px;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
  font-size: 12px;

  @include breakpoint(large) {
    width: 394px;
    height: 502px;
    padding: 40px;
    font-size: 14px;
  }

  svg {
    margin-top: 30px;
    margin-bottom: 24px;
    width: 35px;
    height: 35px;

    @include breakpoint(large) {
      margin-top: 47px;
      margin-bottom: 32px;
    }
  }

  &__header {
    width: 242px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 1px solid $shadeRed;
    background: $white;
    margin-bottom: 24px;
    margin-top: 0;

    @include breakpoint(large) {
      width: 315px;
    }
  }

  &__text {
    width: 216px;
    color: $onyxBlack;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
    margin-bottom: 42px;
    @include breakpoint(large) {
      width: 281px;
    }
  }

  &__form {
    width: 100%;
  }

  &__btn {
    display: inline-flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 3px;
    border: 1px solid #cfcfcf;
    background: $white;
  }
}
