@import '~@ri-digital/web-style-guide/index.scss';

.local-checkout {
  width: 100%;
  max-width: 1200px;
  padding: 0 16px;
  margin: 48px auto;

  @include breakpoint(large) {
    padding: 0 24px;
  }
}
