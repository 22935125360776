@import '~@ri-digital/web-style-guide/index.scss';

.container {
  max-width: $page-max-width;
  margin: 0 auto;
  padding: 0 20px;

  @include breakpoint(large) {
    padding: 0 10px;
  }
}
