@import '~@ri-digital/web-style-guide/index.scss';

.mybag-wrapper {
  display: none;
  flex: 1;

  @include breakpoint(large) {
    display: block;
    padding: 0 0 0 20px;

    // temporary
    min-height: 500px;
  }
}

.mybag-wrapper--mobile {
  display: block;
  height: 100%;

  @include breakpoint(large) {
    display: none;
  }
}

.mybag__promo--mobile {
  display: none;

  @include breakpoint(large) {
    display: block;
  }
}

.mybag {
  padding: 24px 16px 16px;
  background-color: $stone;
  position: relative;
  width: 100%;

  @media screen and (max-width: 1100px) {
    padding: 32px 20px 20px;
  }

  &__header {
    text-align: center;
    padding: 0 0 24px;

    h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin: 0;
      color: $black;
    }

    &__anchor {
      padding-top: 4px;
      display: inline-block;
    }
  }

  &__productslist {
    @include breakpoint(large) {
      overflow: auto;
      max-height: 365px;
    }
  }

  &__products {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__promo {
    position: relative;
    min-height: 54px;
    padding-top: 4px;
  }

  &__totals {
    padding-top: 16px;
    margin-top: 24px;
    width: 100%;

    @include breakpoint(large) {
      padding-top: 0;
      margin-top: 5px;
    }
  }
}

.loader-wrapper-tablet {
  display: none;

  @media (min-width: 769px) and (max-width: 963px) {
    display: block;
    width: 33%;
    padding: 0 0 0 20px;

    &__bag {
      background-color: $stone;
      padding: 24px 16px 16px;
    }
  }
}

.loader-wrapper-desktop {
  display: none;

  @media (min-width: 964px) {
    display: block;
    width: 33%;
    padding: 0 0 0 20px;

    &__bag {
      background-color: $stone;
      padding: 24px 16px 16px;
    }
  }
}

.mybag-wrapper--mobile .mybag {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin-bottom: 8px;

  &__productslist {
    flex: 1;
    overflow: auto;
  }

  &__totals {
    margin-top: 0;
    padding-top: 16px;
  }
}
